import React from "react"

const googlemap = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6136.700997890117!2d-75.57852200000002!3d39.731777!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x51daaadff0629d2b!2sTheSoftCode%20LLC!5e0!3m2!1sen!2sus!4v1638090685568!5m2!1sen!2sus"
      width="100%"
      height="500px"
      title="TheSoftCode Web Design and Development Company"
      allowFullScreen=""
      marginWidth={0}
      marginHeight={0}
      border={0}
      loading="lazy"
    ></iframe>
  )
}

export default googlemap
