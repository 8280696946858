import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import Map from "../components/googlemap"
import { MdPhoneInTalk, MdEmail, MdContacts } from "react-icons/md"
const connect = () => {
  return (
    <Layout>
      <Seo
        title="Connect with us to make something awesome together!"
        description="Let's connect to make something awesome and innovative. We believe that we can do it together! Reach us out for the solution anything related to web technologies."
        keyword="connect, Contact us"
      />
      <Container fluid>
        <br />
        <br />
        <div style={{ display: "grid" }}>
          {/* You can use a GatsbyImage component if the image is dynamic */}
          <StaticImage
            style={{
              gridArea: "1/1",
              // You can set a maximum height for the image, if you wish.
              //    maxHeight: 200,
            }}
            layout="fullWidth"
            // You can optionally force an aspect ratio for the generated image
            // This is a presentational image, so the alt should be an empty string
            alt=""
            // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
            src={"../images/connect.png"}
            formats={["auto", "webp", "avif"]}
          />
          <div
            style={{
              // By using the same grid area for both, they are stacked on top of each other
              gridArea: "1/1",
              position: "relative",
              // This centers the other elements inside the hero component
              placeItems: "center",
              display: "grid",
            }}
          >
            {/* Any content here will be centered in the component */}
            <h1 className="text-white text-center">
              Let's Make Something Awesome Together!
            </h1>
          </div>
        </div>
        <br />
        <Row className="text-center text-white h-100 me-0 ms-0 g-3">
          <Col xs={12} md>
            <MdPhoneInTalk />
            &nbsp;
            <a
              className="text-decoration-none text-reset"
              href="tel:+13022738202"
            >
              +1&nbsp;302-273-8202
            </a>
          </Col>
          <Col xs={12} md>
            <MdEmail />
            &nbsp;
            <a
              className="text-decoration-none text-reset"
              href="mailto:support@thesoftcode.com"
            >
              support@thesoftcode.com
            </a>
          </Col>
          <Col xs={12} md>
            <MdContacts />
            &nbsp;
            <a
              className="text-decoration-none text-reset"
              href="https://g.page/thesoftcode-llc?share"
              target="_blank"
              rel="noopener noreferrer"
            >
              3 Germay Dr, Unit 4 #2104, Wilmington, DE 19804
            </a>
          </Col>
          <Col xs={12} className="h-100 shadow">
            <Map />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default connect
